<template>
    <footer id="footer">

        <!-- Copyrights
        ============================================= -->
        <div id="copyrights">

            <div class="container">

                <div class="row col-mb-30">

                    <div class="col-md-6 copyright-links">
                        {{ $t("footer.copyright-company", [year, $shop_shop.name]) }}
                        <div v-if="legalRoutes">
                            <TextLinks :routes="legalRoutes"></TextLinks>
                        </div>
                        <div v-else>
                            <router-link  :class="'conditions-generales'" :to="{ name : 'shop-conditions-generales', params : { lang : currentLanguageCode, shopPath: $shopContent_path }}">{{ $t("menu.conditions-generales") }}</router-link>
                            /
                            <router-link  :class="'politique-de-confidentialite'" :to="{ name : 'shop-politique-de-confidentialite', params : { lang : currentLanguageCode, shopPath: $shopContent_path }}">{{ $t("menu.politique-de-confidentialite") }}</router-link>
                            /
                            <router-link  :class="'cookies'" :to="{ name : 'shop-cookies', params : { lang : currentLanguageCode, shopPath: $shopContent_path }}">{{ $t("menu.cookies") }}</router-link>
                            /
                            <router-link  :class="'mentions-legales'" :to="{ name : 'shop-mentions-legales', params : { lang : currentLanguageCode, shopPath: $shopContent_path }}">{{ $t("menu.mentions-legales") }}</router-link>
                        </div>
                    </div>

                    <div class="col-md-6 text-md-end">
                        <div class="copyrights-menu">
                            <TextLinks :routes="shopRoutes"></TextLinks>
                        </div>
                        <a v-for="(socialLink, index) in socialLinks" :key="socialLink.type+'_'+index" :href="socialLink.url" :class="'social-icon inline-block si-borderless mb-0 si-'+socialLink.type" target="_blank">
                            <i :class="'icon-'+socialLink.type"></i>
                            <i :class="'icon-'+socialLink.type"></i>
                        </a>
                    
                    </div>

                </div>

                <div class="line line-sm"></div>

                <div class="row">

                    <div :class="vigneronOnlineCssClass">
                        <span v-html="$t('footer.copyright-made-by', [year])"></span>
                        <div>
                            <i class="icon-envelope2"></i> <a href="mailto:contact@vigneron-online.fr">contact@vigneron-online.fr</a> -
                            <a class="contactUs" @click="showContactModal()" href="javascript:void(0);">{{ $t("footer.contactUs.link") }}</a>
                        </div>
                    </div>

                    <div v-if="!isShopSite" class="col-md-6 text-md-end copyrights-menu">
                        <template v-for="(route, index) in vigneronOnlineRoutes">
                            {{ index !== 0 ? '/' : '' }}
                            <router-link  :class="route.shortName+' nav-item'" :to="'/'+currentLanguageCode+route.path" :key="index + '-' + currentLanguageCode">{{ $t("menu."+route.shortName) }}</router-link>
                        </template>
                    </div>

                </div>

            </div>

        </div><!-- #copyrights end -->

    </footer>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { authenticationTypes } from '@fwk-client/store/types';
import { languagesTypes } from '@fwk-client/store/types';
import { types as layoutTypes } from '../../../../store/layout';
import { types as applicationTypes } from '../../../../store/application';
import { Component as CmsComponent } from '@fwk-client/modules/cms/helpers/components';

import TextLinks from '../common/TextLinks.vue';


@Component({
  components: { 
      TextLinks
  },
  // We add name property from store as a name computed
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
      ...mapState({
          isLoggedIn: (state:any) => state.authentication.isLoggedIn
        })
  }
})
export default class Footer extends Vue {

    @Prop({
        type: Object,
        required: false,
    }) readonly component!: CmsComponent | undefined

    year = (new Date()).getFullYear();

    get username() {
        if(this.$store.state.authentication.isLoggedIn) {
            return this.$store.state.authentication.user.profile.displayName;
        }
        return null;
    }

    get vigneronOnlineCssClass() {
        if(this.isShopSite) {
            return "col-12 copyrights-links text-center"    
        }
        return "col-md-6 copyright-links"
    }

    get isShopSite() {
        // True if we are on a site outside of vigneron-online
        return this.$cms_site && !this.$vigneronOnline_site;
    }

    get legalRoutes() {
        if(this.component) {
            var componentProps = this.$cms.components.getComponentProps(this.component);
            if(componentProps.options && componentProps.options.legalLinks) {
                return componentProps.options.legalLinks!.map((link:string) => {
                    return {
                        path: "/" + link,
                        shortName: link,
                        label: this.$t("menu."+link)
                    }
                })
            }
            return [];
        }
        return undefined;
    }

    get socialLinks() {
        if(this.component) {
            var componentProps = this.$cms.components.getComponentProps(this.component);
            if(componentProps.options && componentProps.options.socialLinks) {
                return componentProps.options.socialLinks;
            }
            return [];
        }
        return [];
    }

    get shopRoutes() {
        return this.$store.getters['application/' + applicationTypes.getters.GET_ROUTES_IN_MENU](this, "footer");
    }

    get vigneronOnlineRoutes() {
        return this.$store.getters['application/' + applicationTypes.getters.GET_VIGNERON_ONLINE_MENU](this, "footer");
    }

    onLogoutClick() {
        this.$store.dispatch('authentication/' + authenticationTypes.actions.AUTH_LOGOUT, {app:this});
    }

    showContactModal() {
        this.$store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }
}
</script>
<template>
  <div :class="cssClass">
    <!-- <Header></Header> -->
    <CmsComponent :key="pageShortName + '-header'" :component="headerComponent"></CmsComponent>
    <CmsComponent v-if="showBannerComponent" :key="pageShortName + '-top-banner'" :component="bannerComponent"></CmsComponent>
    <transition @after-leave="afterLeave">
      <router-view></router-view>
    </transition>
    <Footer :component="components.footer"></Footer>
    <SideCart></SideCart>
    <ContactModal></ContactModal>
    <Cookies></Cookies>
  </div>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import Footer from '../panels/layouts/shop/Footer.vue';
import SideCart from '../cms/canvas/shop/SideCart.vue';
import CmsCanvasLayout from '../mixins/CmsCanvasLayout.vue';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import TopBanner from '../cms/canvas/layout/TopBanner.vue';
import Header from '../cms/canvas/layout/Header.vue';

import ContactModal from '../cms/canvas/ContactModal.vue';
import Cookies from '../panels/Cookies.vue';

@Component({
  components: { Footer, SideCart, ContactModal, Cookies, TopBanner, Header }
})
export default class Shop extends mixins(CmsCanvasLayout, GenericPage) {
  
  constructor() {
    super();
  }

  get pageShortName() {
    return RouterUtils.getRouteShortNameFromRoute(this.$route);
  }

  get components() {
    return this.$cms.components.getComponentsFromRoute(this.$route);
	}

  get shopLogo() {
    if(this.$cms_site && !this.$vigneronOnline_site) { return undefined; /* for cms site, shop logo is provided in dedicated header component */ }
    return this.$shopContent_logo;
  }

  get shopBanner() {
    if(this.$cms_site && !this.$vigneronOnline_site) { return undefined; /* for cms site, shop banner is provided in dedicated banner component */ }
    return this.$shopContent_banner;
  }

  get headerComponent() {
    // We check if component is already defined in site config when we are on dedicated site (ex: site of vins-bellet)
    if(this.$cms_site && !this.$vigneronOnline_site && this.components && this.components.header) {
      return this.components.header
    }

    var logoURL = this.shopLogo ? this.getStaticURLFromPath(this.shopLogo.original.path) : undefined;
    var logoAlt = this.$shop_shop.name
    var style = "square";
    if(this.pageShortName == "shop-product" || this.pageShortName == "shop-activity") {
      style = "square-mini"
    }
    return {
        "path" : "canvas/layout/Header",
        "component" : Header,
        "props" : {
            "options" : {
                "logoURL" : logoURL,
                "darkLogoURL" : logoURL,
                "style" : style,
                "topCartOptions" : {
                  "type" : "shop"
                },
            },
            "labels" : {
              "logoAlt" : logoAlt
            }
        }
    }
  }

  get showBannerComponent() {
    var bannerFromSite = this.$cms_site && !this.$vigneronOnline_site && this.components && this.components.banner;
    return (bannerFromSite || this.shopBanner) && this.pageShortName != "shop-product" && this.pageShortName != "shop-activity";
  }

  get bannerComponent() {
    // We check if component is already defined in site config when we are on dedicated site (ex: site of vins-bellet)
    if(this.$cms_site && !this.$vigneronOnline_site && this.components && this.components.banner) {
      return this.components.banner
    }

    var bannerURL = this.showBannerComponent ? this.getStaticURLFromPath(this.shopBanner.original.path) : undefined;
    var bannerAlt = this.$shop_shop.name
    return {
        "path" : "canvas/layout/TopBanner",
        "component" : TopBanner,
        "props" : {
            "options" : {
                "bannerURL" : bannerURL,
            },
            "labels" : {
              "bannerAlt" : bannerAlt
            }
        }
    }
  }

  get cssClass() {
    return 'layout shop-layout '+this.pageShortName+'-page';
  }

  beforeMount() {
    this.addComponentsToBeMounted();
  }

  beforeUpdate() {
    this.addComponentsToBeMounted();
  }

  addComponentsToBeMounted() {
    var componentsToBeMounted:any[] = [];
    // We do not add the Header component as it may already been included in site configuration
    if(this.showBannerComponent && !(this.components && this.components.banner)) {
      componentsToBeMounted.push(this.bannerComponent);
    }
    // We do not add the Header component as it may already been included in site configuration
    if(!(this.components && this.components.header)) {
      componentsToBeMounted.push(this.headerComponent);
    }
    this.$cms.components.addComponentToBeMounted(componentsToBeMounted)
  }

  mounted() {
    // We trigger the scroll when the layout is mounted coming from an other layout.
    // This event is catched by the generic triggerScroll event listener until the scrollBehavior method is called.
    if(process.env.CONSOLE == "LOG") {
      console.log("SHOP LAYOUT - MOUNTED - WE TRIGGER SCROLL");
    }
    this.$root.$emit('triggerScroll');
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior when there is a transition to a new route in the same Layout
   */
  afterLeave () {
    if(process.env.CONSOLE == "LOG") {
      console.log("SHOP LAYOUT - AFTER LEAVE - WE TRIGGER SCROLL");
    }
    this.$root.$emit('triggerScroll');
  }

}
</script>